import React, { useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import { Spinner } from 'wix-ui-tpa';
import { FormViewerContext } from '@wix/form-viewer/widget';
import { useExperiments } from '@wix/yoshi-flow-editor';
import loadable from '@wix/yoshi-flow-editor/loadable';
import { EXPERIMENTS, VIEWER_HOOKS } from '../../../constants';
import { AppWidgetProps, ModalType } from '../../../types/common';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { openBillingPage } from '../../../utils/openBillingPage';
import CssVars from '../CssVars';
import { AlertModal } from './AlertModal';
import styles from './Widget.scss';
import { WidgetContext } from './WidgetContext';

const LazyList = loadable(() => import('./List'));
const LazyStatus = loadable(() => import('./Status'));
const LazyCheckout = loadable(() => import('./Checkout'));
const LazyCheckoutV2 = loadable(() => import('./CheckoutV2'));
const LazyRestricted = loadable(() => import('./Restricted'));
const LazyUpgradeModal = loadable(() => import('./UpgradeModal'));

const Widget: React.FC<AppWidgetProps> = React.memo((props) => {
  return (
    <WidgetContext>
      <CssVars id={props.host.id} style={props.host.style} />
      <FormViewerContext {...props}>
        <ViewSwitch {...props} />
      </FormViewerContext>
      <Modal {...props} />
    </WidgetContext>
  );
});

const Modal: React.FC<AppWidgetProps> = ({
  modal,
  showUpgradeModal,
  closeUpgradeModal,
  metaSiteId,
  biUpgradeReferralClick,
}) => {
  const { experiments } = useExperiments();
  const isAlertModalsEnabled = experiments.enabled(EXPERIMENTS.ALERT_MODALS);
  const onUpgrade = React.useCallback(() => {
    biUpgradeReferralClick('checkout-upgrade-modal');
    metaSiteId && openBillingPage(metaSiteId);
  }, [biUpgradeReferralClick, metaSiteId]);
  if (isAlertModalsEnabled) {
    if (modal.type === ModalType.None) {
      return null;
    }
    const { type, onClose } = modal;

    switch (type) {
      case ModalType.Upgrade:
        return <LazyUpgradeModal onClose={onClose} onPreview={onClose} onUpgrade={onUpgrade} />;
      default:
        return <AlertModal {...modal} />;
    }
  } else {
    return showUpgradeModal ? (
      <LazyUpgradeModal onClose={closeUpgradeModal} onPreview={closeUpgradeModal} onUpgrade={onUpgrade} />
    ) : null;
  }
};

const ViewSwitch: React.FC<AppWidgetProps> = React.memo((props) => {
  const { subPage, navigateToHomePage } = props;
  const [isCheckoutComponentLoaded, setIsCheckoutComponentLoaded] = useState(false);
  const emptyState = useMemo(() => ({ isInWidget: false, onClick: () => navigateToHomePage() }), [navigateToHomePage]);
  const { experiments } = useExperiments();
  const loadersEnabled = experiments.enabled(EXPERIMENTS.LOADERS);
  const LazyCheckoutComponent = experiments.enabled(EXPERIMENTS.ADDITIONAL_INFO_IN_CHECKOUT)
    ? LazyCheckoutV2
    : LazyCheckout;

  switch (subPage.name) {
    case 'list':
    default:
      return <LazyList emptyState={emptyState} interactionEnd={PackagePickerInteractions.ListPageLoaded} {...props} />;
    case 'checkout':
      return loadersEnabled ? (
        <CheckoutLoader loading={!props.isCheckoutDataInitialized || !isCheckoutComponentLoaded}>
          <LazyCheckoutComponent
            {...props}
            onLoad={() => setIsCheckoutComponentLoaded(true)}
            viewMode={props.host.viewMode}
            siteStyles={props.host.style}
            integrationData={subPage.checkoutData.integrationData}
          />
        </CheckoutLoader>
      ) : (
        <LazyCheckoutComponent
          {...props}
          viewMode={props.host.viewMode}
          siteStyles={props.host.style}
          integrationData={subPage.checkoutData.integrationData}
        />
      );
    case 'status':
      return (
        <LazyStatus
          {...props}
          ownerDemo={subPage.statusData.ownerDemo}
          integrationData={subPage.statusData.purchaseData.checkoutData.integrationData}
        />
      );
    case 'restricted':
      return <LazyRestricted {...props} />;
  }
});

const CheckoutLoader: React.FC<{ loading: boolean }> = ({ loading, children }) => {
  const ref = useRef<HTMLHeadingElement>(null);
  const { experiments } = useExperiments();

  useEffect(() => {
    if (experiments.enabled(EXPERIMENTS.SCROLL_INTO_CHECKOUT) && ref.current) {
      smoothScrollIntoView(ref.current, { block: 'center' });
    }
  }, [experiments]);

  return (
    <div ref={ref} className={cn(styles.loaderRoot, { [styles.loading]: loading })}>
      <div className={styles.content}>{children}</div>
      {loading && (
        <div data-hook={VIEWER_HOOKS.CHECKOUT_LOADER} className={styles.loaderContainer}>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default Widget;
